import React, { Component } from 'react'
import { graphql } from 'gatsby'
import Reveal from 'react-reveal/Reveal'
import axios from 'axios'
import Link from 'gatsby-link'
import Helmet from 'react-helmet'

import TemplateWrapper from '../components/layout'
import Map from '../components/Map/'
import serialize from '../utils/serialize'

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

class ContactPage extends Component {

  state = {
    form: {
      name: '',
      email: '',
      number: '',
      message: ''
    },
    validation: {
      name: true,
      email: true,
      number: true,
      message: true
    },
    confirmation:''
  }

  handleChange = name => event => {
    let { form, validation } = this.state;

    const setNestedKey = (obj, path, value) => {
      if (path.length === 1) {
        obj[path] = value;
        return;
      }
      return setNestedKey(obj[path[0]], path.slice(1, path.length), value);
    }

    setNestedKey(form, name.split('.'), event.target.value);

    if (event.target.value == '') {
      setNestedKey(validation, name.split('.'), false);
    } else {
      setNestedKey(validation, name.split('.'), true);
    }

    this.setState({ form, validation });

  };

  handleSubmit(event) {

    event.preventDefault()

    let { form } = this.state

    this.setState({ confirmation: '' })

    if ( form.name != '' && form.email != '' && form.number != '' && form.message != '' ) {


      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({
          "form-name": 'contact',
          ...this.state.form
        })
      })
        .then((response) => {
          this.setState({ confirmation: 'Thanks for your enquiry. A member of the team will be in touch shortly.' })
        })
        .catch(error => console.log(error))

    }

  }

  render() {

    let valid = this.state.validation
    let { confirmation } = this.state

    let data = this.props.data.wordpressPage

    return (
      <TemplateWrapper {...this.props}>
        <div>
          <Helmet
            bodyAttributes={{
              class: 'r3__contact'
            }}
          >
            <title>{ data.yoast_meta.yoast_wpseo_title }</title>
            <meta name="description" content={ data.yoast_meta.yoast_wpseo_metadesc } />
          </Helmet>
          <div className="r3__section r3--dark r3--no-head">
            <div className="r3__content">
              <h1>{ data.acf.title }</h1>
              <div className="r3__narrow">
                <Reveal effect="fadeInUp">
                  <p dangerouslySetInnerHTML={{__html: data.acf.content}}/>
                  <Link className="contact-email" to={`mailto:${data.acf.email}`}>{ data.acf.email }</Link>
                </Reveal>
              </div>
            </div>
          </div>

          <div className="r3__section">
            <div className="r3__content">
              <div className="r3__narrow">
                <Reveal effect="fadeInUp">
                  { confirmation ?
                    <p className="r3__confirmation">{confirmation}</p> :
                    <form ref='form' className="form" name='contact' onSubmit={this.handleSubmit.bind(this)} data-netlify="true" data-netlify-honeypot="bot-field">
                      <h3>{ data.acf.form_title }</h3>
                      <div style={{display: 'none'}}><input name="bot-field" /></div>
                      <input type="text" className={valid.name ? '' : 'error'} onBlur={this.handleChange('name')} onChange={this.handleChange('name')} name="name" placeholder="Name*" />
                      <input type="text" className={valid.email ? '' : 'error'} onBlur={this.handleChange('email')} onChange={this.handleChange('email')} name="email" placeholder="Email*" />
                      <input type="text" className={valid.number ? '' : 'error'} onBlur={this.handleChange('number')} onChange={this.handleChange('number')} name="number" placeholder="Phone*" />
                      <textarea name="message" className={valid.message ? '' : 'error'} onBlur={this.handleChange('message')} onChange={this.handleChange('message')} placeholder="What's up?*" />
                      <button type="submit" className="btn btn--black">Submit →</button>
                    </form>
                  }
                </Reveal>
              </div>

            </div>
          </div>

          <div className="r3__section r3--dark">
            <div className="r3__content">
              <Reveal effect="fadeInUp">
                <Map />
              </Reveal>
            </div>
          </div>

        </div>
      </TemplateWrapper>
    )
  }
}

export default ContactPage

export const pageQuery = graphql`
  query contactQuery {
    wordpressPage(title: {eq: "Contact"}) {
      title
      acf {
        title
        content
        email
        form_title
      }
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
    }
  }
`
