import React, { Component } from 'react'
import Link from 'gatsby-link'
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";

const mapStyle = require("./style.json");

class Map extends Component {

  render() {
    const location = { lat: -37.7992906, lng: 144.983937 };

    const MapWithAMarker = withScriptjs(withGoogleMap(props =>
      <GoogleMap
        defaultZoom={12}
        defaultCenter={location}
        options={{
          styles: mapStyle,
          mapTypeControl: false,
          fullscreenControl: false,
          rotateControl: false,
          clickableIcons: true,
        }}
      >
        <Marker
          position={location}
        />
      </GoogleMap>
    ));

    return (
      <div className='r3__map__container'>
        <MapWithAMarker
          googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBggRes-V4nDmqNqhINUR6tU8T4UCshrMI&v=3.exp&libraries=geometry,drawing,places"
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={{ height: `250px`, margin: `80px 0 20px` }} />}
          mapElement={<div style={{ height: `100%` }} className={'r3__map'} />}
        />
        <a href='https://www.google.com.au/maps?q=35+Johnston+St,+Collingwood+VIC+3066' target='_blank'>
          <p className='r3__map__text'>35 Johnston St, Collingwood VIC 3066</p>
        </a>
      </div>
    );
  }
}

export default Map
